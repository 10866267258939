<template>
  <HomePageContent
    :show-action-buttons="!inAppView"
  />
</template>

<script>
import { useCookieWrapper } from '@@/composables/useCookieWrapper';
import { useUserStore } from '@@/stores/User';
import AppViewMixin from '@@/utils/AppViewMixin';
import { navigateTo } from 'nuxt/app';

export default {
  name: 'HomePage',

  mixins: [AppViewMixin],

  async setup() {
    const userStore = useUserStore();

    // NOTE: The query is accessed directly here instead of using AppViewMixin because asyncData()
    // doesn't have access to component instance.
    const { fullPath, query } = useRoute();
    const inAppView = query.in_app_view === 'true';
    const isLoggedIn = !userStore.isGuest;

    // Redirect if logged in and not rendered in mobile app
    if (isLoggedIn && !inAppView) {
      const router = useRouter();
      const cookie = useCookieWrapper('last-page');

      if (cookie.value
        && cookie.value !== fullPath
        && router.resolve(cookie.value).matched.length
      ) {
        navigateTo(cookie.value);
      }
      else {
        navigateTo({ path: '/user/favorites/locations', query });
      }
    }
  },
};
</script>
